import { graphql, useStaticQuery } from "gatsby"

const useBlogsAll = () => {
  const data = useStaticQuery(graphql`
    query {
      allMdx(
        sort: { fields: frontmatter___date, order: DESC }
        filter: {
          fileAbsolutePath: { regex: "/blog/" }
          frontmatter: { draft: { ne: true } }
        }
      ) {
        nodes {
          excerpt(pruneLength: 100)
          timeToRead
          frontmatter {
            date
            title
            path
            tags
            category
            tldr
            link
            cover {
              publicURL
              size
              childImageSharp {
                fluid(maxHeight: 1200) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            date
          }
        }
      }
    }
  `)

  return data.allMdx.nodes.map(blogEdge => ({
    tags: blogEdge.frontmatter.tags,
    cover: blogEdge.frontmatter.cover,
    title: blogEdge.frontmatter.title,
    excerpt: blogEdge.frontmatter.tldr,
    timeToRead: blogEdge.timeToRead,
    date: blogEdge.frontmatter.date,
    link: blogEdge.frontmatter.link,
  }))
}

export default useBlogsAll
