import React from "react"
import styled from "styled-components"
import Img from "gatsby-image"
import SiteLink from "../../bits/siteLink"
import useBlogsAll from "../../hooks/blogListAllHook"
import Fade from "react-reveal/Fade"

const Block = styled.div`
  border-radius: 4px;
  margin-bottom: 1rem;
  position: relative;
  grid-column: span 4;
  display: flex;
  flex-flow: column;
  background: ${props => (props.invert ? `var(--color-white-700)` : ``)};
  text-align: left;
  align-content: flex-start;

  color: ${props =>
    props.invert ? `var(--color-black-500)` : `var(--color-black-500)`};
  justify-content: space-between;
  transition: var(--transition);

  p {
    align-self: flex-start;
  }

  h3 {
    margin: 0;
  }

  h1 {
    margin: 1rem 0;
  }

  button {
    color: ${props =>
      props.invert ? `var(--color-white-500)` : `var(--color-black-500)`};
  }

  @media only screen and (max-width: 767px) {
    padding: 0;
    margin-bottom: 2rem;
  }

  &:hover {
    transform: scale(1.01, 1.01);
    box-shadow: 0 25px 40px 0 rgba(0, 0, 0, 0.08);
  }

  &:hover a {
    background-size: 100% 100%;
  }
`

const Content = styled.div`
  padding: var(--var-padding-s);
  background-color: var(--color-white-700);
`

const PostListImage = styled(Img)`
  transition: transform 0.2s ease-in, box-shadow 0.3s ease-in-out;
`

const Paragraph = styled.p`
  font-size: 0.95rem;
  line-height: 200%;
  color: var(--color-secondary-500);
`

const BlogListingAll = () => {
  const blogList = useBlogsAll()

  return blogList.map(blog => (
    <Fade bottom>
      <Block className="noeffect" key={blog.title}>
        {" "}
        <PostListImage
          fluid={blog.cover.childImageSharp.fluid}
          alt={blog.title}
        />
        <Content>
          <h3>
            <SiteLink to={blog.link} target="_blank" rel="noopener">
              {blog.title}
            </SiteLink>
          </h3>
          <Paragraph className="noeffect"> {blog.excerpt}</Paragraph>
        </Content>
      </Block>
    </Fade>
  ))
}

export default BlogListingAll
