import React from "react"
import Layout from "../template/index"
import SEO from "../utils/seo"
import Boxed from "../bits/box"
import PageTitle from "../bits/pageTitle"
import styled from "styled-components"
import BlogListingAll from "../components/BlogList/blogListAll"

const Row = styled.section`
  padding: var(--var-padding-s);
  background: var(--color-background);
`

const Box = styled(Boxed)`
  max-width: var(--page-container-s);
`

const Right = styled.main`
  grid-area: right;
`

const Blogs = () => {
  return (
    <Layout>
      <SEO title="Blog" />
      <Row>
        <Box>
          <PageTitle
            title="Blog"
            subtitle="Articles on Tech and Biz"
            description="This is a glimpse of our knowledge and experience in various industries."
          />
          <Right>
            <Row id="latest">
              <small>Latest</small>
              <BlogListingAll />
            </Row>
          </Right>
        </Box>
      </Row>
    </Layout>
  )
}

export default Blogs
